<template>
  <div class="content">
    <Breadcrumb :data="nav" :is$t="true" />
    <Box v-loading="loading" class="pane">
      <div slot="header" class="pane-header">
        <h4 class="title">{{ detail.title | textFilter }}</h4>
        <p class="sub-title">
          <span>作者：</span>
          <span class="value">{{
            detail.author | textFilter
          }}</span>

          <span>{{ $t('3eb37ca') }}：</span>
          <span>{{
            detail.releaseTime | textFilter
          }}</span>

          <span class="line">|</span>

          <span>{{ $t('52c01f1') }}：</span>
          <span class="value">{{ detail.source | textFilter }}</span>

          <span>点击：</span>
          <span class="value">{{ detail.clickNum | textFilter }}</span>

          <span>{{ $t('a9435f5') }}：</span>
          <span class="value">{{ detail.commentsNum | textFilter }}</span>
        </p>
      </div>
      <div class="pane-body">
        <div class="ql-editor" v-html="detail.content"></div>
      </div>
    </Box>
  </div>
</template>

<script>
import Box from '@/components/Box'
import Breadcrumb from '@/components/Breadcrumb'

export default {
  components: {
    Box,
    Breadcrumb,
  },
  props: {
    detailData: {
      type: Object,
      default: () => ({
        loading: false,
        data: {}
      })
    },
  },
  data() {
    return {
    }
  },
  computed: {
    loading() {
      return this.detailData.loading
    },
    detail() {
      return this.detailData.data
    },
    nav() {
      return [
        { name: 'bae89f7', path: '/communication' },
        { name: 'd29b5da', path: '/communication/forum' },
        { name: this.detailData.data.title, path: '' }
      ]
    }
  }
}
</script>

<style scoped lang="less">
.content {
  width: 1200px;
  margin: 0 auto;
}

.pane-header {
  padding: 30px;
  border-bottom: 1px solid #eee;

  .title {
    font-size: 20px;
    color: #333333;
    line-height: 30px;
    font-weight: 500;
    text-align: center;
    padding-bottom: 30px;
  }

  .sub-title {
    font-size: 13px;
    color: #999999;
    text-align: center;
  }
}

.pane-body {
  font-size: 14px;
  color: #999999;
  line-height: 1.5;

  p {
    padding-bottom: 30px;
  }
}
.value {
  margin-right: 40px
}
.line {
  margin: 0 40px;
}
</style>
