<template>
  <div>
    <ArticleDetail :detail-data="detail" />
    <CommentList
      :detail-data="detail"
      :get-detail="getDetail"
    />
  </div>
</template>

<script>
import ArticleDetail from '@/views/communication/forum/Detail/ArticleDetail'
import CommentList from '@/views/communication/forum/Detail/CommentList'
import api from '@/api/communicationForum'
export default {
  components: {
    ArticleDetail,
    CommentList
  },
  data() {
    return {
      detail: {
        data: {},
        loading: false
      },

    }
  },
  mounted() {
    this.getDetail()
  },
  methods: {
    getDetail() {
      const id = this.$route.query.id
      this.detail.loading = true
      api.getDetail(id)
        .then((res) => {
          this.detail.data = res
        })
        .catch(() => {})
        .then(() => {
          this.detail.loading = false
        })
    },
  },
}
</script>

<style lang="less" scoped>

</style>
