<template>
  <div class="content">
    <div class="box">
      <div class="comment-form">
        <div class="comment-input">
          <el-input
            v-model="mainComment"
            :autosize="{ minRows: 2, maxRows: 4}"
            type="textarea"
            placeholder="请输入评论信息"
            maxlength="1000"
          />
          <div class="words-num">{{ mainComment.length || 0 }}/1000</div>
        </div>

        <el-button
          :loading="submitLoading"
          type="primary"
          class="submit-btn"
          @click="submitComments"
        >
          {{ $t('284d1ae') }}
        </el-button>
      </div>

      <hr class="line" />

      <div class="comment-list">
        <div class="total">
          全部评论
          <span>{{ detailData.data.commentsNum }}</span>
        </div>
        <div
          v-for="(comment, idx) in commentList"
          :key="idx"
          class="comment"
        >
          <Comment
            :detail="comment"
            :get-comment-list="getCommentList"
            :submit-replay="submitReplay"
            :user-id="userId"
          />
          <div
            v-if="comment.children && comment.children.length > 0"
            class="replay-list"
          >
            <div
              v-for="(replay, replayIdx) in comment.children"
              :key="replayIdx"
            >
              <Comment
                class="replay"
                :detail="replay"
                :get-comment-list="getCommentList"
                :submit-replay="submitReplay"
                :user-id="userId"
              />
              <hr v-if="replayIdx !== comment.children.length - 1" class="replay-line" />
            </div>
          </div>
          <hr v-if="idx !== commentList.length - 1 " class="line" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Comment from './CommentList/Comment'
import api from '@/api/communicationForum'
import { mapGetters } from 'vuex'
export default {
  components: {
    Comment,
  },
  props: {
    detailData: {
      type: Object,
      default: () => ({})
    },
    getDetail: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      mainComment: '',
      commentList: [],
      submitLoading: false
    }
  },
  computed: {
    ...mapGetters(['userInfo']),
    userId() {
      return this.userInfo.id
    }
  },
  mounted() {
    this.getCommentList()
  },
  methods: {
    getCommentList() {
      const id = this.$route.query.id
      api.getCommentList(id)
        .then((res) => {
          this.commentList = res.content
        })
    },
    resetCommentForm() {
      this.mainComment = ''
    },
    submitComments() {
      if (!this.mainComment) {
        this.$message.error('请输入评论信息！')
        return
      }
      const bbsId = this.$route.query.id
      const params = {
        type: 'COMMENTS',
        content: this.mainComment
      }
      this.submitLoading = true
      api.addComment(bbsId, params)
        .then((res) => {
          this.$message.success('发布成功！')
          this.getCommentList(bbsId)
          this.getDetail()
          this.resetCommentForm()
          this.submitLoading = false
        })
        .catch(() => {
          this.$message.error('发布失败！')
          this.submitLoading = false
        })
    },
    submitReplay(data) {
      if (!data.content) {
        this.$message.error('请输入评论信息！')
        return
      }
      const bbsId = this.$route.query.id
      const params = {
        type: 'REPLY',
        content: data.content,
        commentsId: data.commentsId
      }
      return new Promise((resolve, reject) => {
        api.addComment(bbsId, params)
          .then((res) => {
            resolve()
            this.$message.success('发布成功！')
            this.getDetail()
            this.getCommentList(bbsId)
          })
          .catch((error) => {
            reject(error)
            this.$message.error('发布失败！')
          })
      })
    }
  },
}
</script>

<style lang="less" scoped>
.content {
  width: 1200px;
  margin: 0 auto;
}
.box {
  background: #ffffff;
  box-shadow: 0px 2px 22px 0px rgb(218 222 224 / 50%);
  margin-bottom: 20px;
  padding: 30px 0;
}
.comment-form {
  margin: 10px 0;
  padding: 0 30px;
}
.comment-input {
  margin-bottom: 10px;
  /deep/ textarea {
    padding: 12px 12px 5px 12px;
    resize: none !important;
  }
}
.replay-list {
  background: #F7F7F7;
  padding-top: 30px;
  border: 1px solid #EEEEEE;
   border-radius: 4px;
}
.replay {
  padding: 0 20px 0 40px;
}
.replay-line {
  border: none;
  border-top: 1px solid #EEEEEE;
  margin-bottom: 30px;
}
.comment-list {
  padding: 20px 30px;
}
.total {
  color: #333;
  margin-bottom: 30px;
  &>span {
    margin-left: 10px;
    color: #999999;
  }
}
.comment {
  margin-bottom: 20px;
}
.comment-statis {
  margin-bottom: 10px;
}
.submit-btn {
  padding: 9px 0;
  width: 120px;
}
.line {
  border: none;
  border-top: 1px solid #EEEEEE;
  margin-top: 30px;
}
.words-num {
  color: #ccc;
  text-align: right;
  margin-top: 10px;
}
</style>
