<template>
  <div class="comment-item">
    <img class="head-img" :src="detail.picture" alt="" />
    <div class="comment-box">
      <span class="name">{{ detail.nickname }}</span>
      <div class="content">
        <span v-if="detail.replyName">
          <span>回复</span>
          <span class="name">
            {{ detail.replyName }}
          </span>
          ：
        </span>
        {{ detail.content }}
      </div>
      <div class="info-box">
        <div class="info">
          <span>{{ detail.createTime }}</span>
          <span v-if=" detail.num">评论 {{ detail.num }}</span>
          <span
            v-if="userId !== detail.userId && detail.validity"
            class="replay-btn"
            @click="toggleFormVis"
          >
            回复
          </span>
        </div>
        <div
          v-if="formVisible"
          class="comment-form"
        >
          <el-input
            v-model="replayValue"
            :autosize="{ minRows: 2, maxRows: 4}"
            type="textarea"
            class="comment-input"
            :placeholder="`回复 ${detail.nickname}：`"
            @blur="onInputBlur"
            @focus="onInputFocus"
          />
          <div class="operation">
            <el-button
              class="operation-btn"
              type="primary"
              :loading="loading"
              @click="submit"
            >{{ $t('284d1ae') }}
            </el-button>
            <el-button class="operation-btn" @click="toggleFormVis">取消</el-button>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  props: {
    detail: {
      type: Object,
      default: () => ({})
    },
    submitReplay: {
      type: Function,
      default: () => {}
    },
    userId: {
      type: Number,
      default: -1
    }
  },
  data() {
    return {
      formVisible: false,
      replayValue: '',
      loading: false,
      isFocus: false
    }
  },
  methods: {
    onInputFocus() {
      this.isFocus = true
    },
    onInputBlur() {
      if (!this.replayValue) {
        this.isFocus = false
      }
    },
    toggleFormVis() {
      this.formVisible = !this.formVisible
    },
    submit() {
      const data = {
        content: this.replayValue,
        commentsId: this.detail.id
      }
      this.loading = true
      this.submitReplay(data)
        .then(() => {
          this.loading = false
          this.toggleFormVis()
          this.replayValue = ''
        })
        .catch(() => {
          this.loading = false
        })
    },
  },
}
</script>

<style lang="less" scoped>
.comment-item {
  margin-bottom: 20px;
  display: flex;
}
.comment-box {
  width: 100%;
}
.head {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}
.name {
  color: #333333;
  font-weight: bold;
}
.content {
  line-height: 1.5;
  margin-top: 12px;
  color: #666;
}
.head-img {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}
.info-box {
  line-height: 1.5;
}
.comment-form {
  margin-top: 30px;
}
.comment-input {
  margin-bottom: 20px;
  /deep/ textarea {
    padding: 12px 12px 5px 12px;
    resize: none !important;
  }
}
.operation-btn {
  padding: 9px 0;
  width: 120px;
  margin-right: 30px;
}
.replyName {
  margin-left: 10px;
  color: #8590a6;
  &>span {
    color: #8590a6;
    margin-right: 10px;
  }
}
.info {
  font-size: 12px;
  color: #999;
  margin-top: 20px;
  span {
    margin-right: 10px;
  }
}
.replay-btn {
  cursor: pointer;
}
.operation {
  text-align: center;
}
</style>
